import React from "react";
import { datadogRum } from "@datadog/browser-rum";
import { connect } from "react-redux";
import { PrimaryButton } from "@ddm-design-system/button";
import Modal from "@ddm-design-system/modal";
import { Body, PageTitle } from "@ddm-design-system/typography";
import { ReactComponent as ErrorCrossIcon } from "../../../assets/icons/error-cross.svg";
import { IAppState } from "../../../store";
import { getCurrentContent } from "../../../store/content/selectors";
import { ErrorModalContent, ErrorModalGraphic, StyledErrorLineIcon } from "./errorBoundary.styles";

const ENABLE_IN_DEVELOPMENT = false;
const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
const DATADOG_APP_ID = process.env.REACT_APP_DATADOG_APP_ID;
const DATADOG_SITE = "datadoghq.eu";

interface IProps {
  content?: any;
}

interface IState {
  modalVisible: boolean;
  error?: Error;
  configured: boolean;
}

export class ErrorBoundary extends React.Component<IProps, IState> {
  state: IState = {
    modalVisible: false,
    configured: false
  };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { modalVisible: true };
  }

  componentDidMount() {
    if (process.env.NODE_ENV === "production" || ENABLE_IN_DEVELOPMENT) {
      if (localStorage.getItem("cookieConsent") === "true") {
        this.configureLogs();
      }
    }
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    this.logError(error, errorInfo);
    this.setState({ modalVisible: true, error });
  }

  public configureLogs() {
    if (!this.state.configured && DATADOG_CLIENT_TOKEN && DATADOG_APP_ID) {
      datadogRum.init({
        applicationId: DATADOG_APP_ID,
        clientToken: DATADOG_CLIENT_TOKEN,
        site: DATADOG_SITE,
        service: "control-tower",
        version: process.env.npm_package_version,
        env: "staging",
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        allowedTracingUrls: [/^https:\/\/.*\.dmbuddy\.io$/],
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        beforeSend: (log: any) => {
          if (log?.http?.status_code === 401) {
            return false;
          }
          if (typeof log.message !== "string") {
            return true;
          }
          return !log.message?.startsWith("Non-Error exception captured");
        }
      });
      this.setState({ configured: true });
    }
  }

  public logError(error: any, errorInfo: any) {
    if (error && (error.status === 401 || error === 401)) {
      return;
    }

    if (process.env.NODE_ENV === "production" || ENABLE_IN_DEVELOPMENT) {
      // eslint-disable-next-line no-console
      console.log("DATADOG ERROR:", error, errorInfo);
    }
  }

  render() {
    const { content, children } = this.props;
    const { error, modalVisible } = this.state;

    return (
      <>
        {!error ? (
          children
        ) : (
          <Modal
            isOpen={modalVisible}
            onRequestClose={() => this.setState({ modalVisible: false })}
          >
            <ErrorModalContent>
              <PageTitle>{content.title}</PageTitle>
              <Body>{content.subtitle}</Body>
              <ErrorModalGraphic>
                <ErrorCrossIcon />
                <StyledErrorLineIcon />
              </ErrorModalGraphic>
              <PrimaryButton onClick={() => window.location.reload()}>
                {content.refresh_button}
              </PrimaryButton>
            </ErrorModalContent>
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  const content = getCurrentContent(state);

  return {
    content: content ? content.controlTowerError : {}
  };
};

export default connect<any, any, any, any>(mapStateToProps, null)(ErrorBoundary);
